import React from "react";
import Image from "../image";
import MaintenanceFeatureMP4 from '../../assets/force/force-maintenance-vid.mp4';
import VehicleTrailsFeatureMP4 from '../../assets/force/force-vehicle-trails-vid.mp4';
import TireCheckFeatureMP4 from '../../assets/force/force-tire-check-vid.mp4';

const ModalForceContent = () => (
  <>
    <h2 className="modal-main__title">Force - Frontend Features</h2>
    <div className="modal-main__content">
      {/********** TIRE CHECK FEATURE **********/}
      <div className="modal-main__project-title">
        <h3>Tire Check</h3>
      </div>
      <video autoPlay="true" loop="true" style={{ width: "100%" }}>
        <source src={TireCheckFeatureMP4} type="video/mp4" />
      </video>
      <div className="modal-main__desc">
        <p>
          Led the project development and implementation of the Tire Check feature that allows fleet managers to monitor the health of each tire on all their vehicles. Tire scans/assets are uploaded via the mobile app and the Platform team's ML models provide an analysis on each scan.
          This table gives an instant view of every tires' corresponding severity level. 
        </p>
        <p>
          Clicking onto a vehicle will drill into a presentation screen of every tire scan. 
          See previously uploaded scans and their corresponding severity levels. Images are loaded asyncrounsly and cached onto the browser for quick retrieval when screen is accessed again.
        </p>
        <p>
          Expand the tire scan image and you are presented with a pop-up modal that displays all issues (if any) detected on a tire scan. 
          Each issue is identified as a rectangle on the scan, with a number label that associates it with the issue description. Hover over the issue to highlight area identified on scan. 
        </p>
      </div>
      <div className="modal-main__image cards">
        <div style={{ width: "35%" }} >
          <Image imgSrc="force/force-tire-birdseye.png" imgAlt="tire-birdseye" />
        </div>
        <div style={{ width: "35%" }} >
          <Image imgSrc="force/force-tire-scan.png" imgAlt="tire-scan" />
        </div>
      </div>
      <div className="modal-main__desc">
        <p>
          Above are two alternate views of the Tire Check feature under the Vehicle Health tab inside each individual Vehicle page.
        </p>
      </div>
      {/********** MAINTENANCE FEATURE **********/}
      <div className="modal-main__project-title">
        <h3>Maintenance Feature</h3>
      </div>
      <div className="modal-main__image">
        <Image imgSrc="force/force-maintenance-details.png" imgAlt="maintenance-details" />
      </div>
      <div className="modal-main__desc">
        <p>
          The addition of this new service allows customers to keep track of all maintenance related payments and receive notifications for when a maintenance service is due. 
          This screen gives the user an overview of what they've spent on maintaining the vehicle, with the ability to configure reminders based on the vehicle's <i>Next Service</i> odometer value. 
        </p>
      </div>
      <video autoPlay="true" loop="true" style={{ width: "100%" }}>
        <source src={MaintenanceFeatureMP4} type="video/mp4" />
      </video>
      <div className="modal-main__desc">
        <p>
          The user will upload receipt documents (Image or PDF format) related to the maintenance job and input any other pertinant information. 
          User will be able to preview the uploaded document and zoom into the document as they hover the cursor over the preview area. 
        </p>
      </div>
      {/********** VEHICLE TRAILS FEATURE **********/}
      <div className="modal-main__project-title">
        <h3>Vehicle Trails Feature</h3>
      </div>
      <div className="modal-main__desc">
        <p>
          Vehicle Trails is a powerful new feature that was released at the start of 2023 to kickoff the year. Enabling Fleet managers to instantly view the entire 'Trail' taken on any given day. 
          The user is able to easily switch between vehicles and dive into individual trips to see infractions along that trail. 
        </p>
        <p>
          Different types of infractions include rapid accelerations, harsh breaking, speeding and idling. Selecting an infraction will highlight the corresponding icons red on the map. 
        </p>
      </div>
      <video autoPlay="true" loop="true" style={{ width: "100%" }}>
        <source src={VehicleTrailsFeatureMP4} type="video/mp4" />
      </video>
    </div>
    {/********** IDLING REPORT **********/}
    <div className="modal-main__project-title">
      <h3>Idling Report</h3>
      </div>
      <div className="modal-main__image">
        <Image imgSrc="force/force-idling-report-1.png" imgAlt="idling-report-1" />
      </div>
      <div className="modal-main__image">
        <Image imgSrc="force/force-idling-report-2.png" imgAlt="idling-report-2" />
      </div>
      <div className="modal-main__desc">
        <p>
          Implemnted new idling report feature in Force application that gives fleet managers a snapshot view of each vehicles' idling data. See the percentage of time each vehicle or driver spends idling when the engine is running. 
          Fetches, parses and aggregates RESTful API data to compile in a reports table format. Raw trip data is aggregated based on selected filter (date, vehicle or driver), calculations are performed on client side, stored in NgRx and then displayed on table.
        </p>
        <p>
          With fuel prices on the rise, the savings calculator gives users an immidiate idea of potential savings if idling durations were reduced. Users can dynamically tweak input values to get an updated savings value. 
        </p>
      </div>
  </>
);

export default ModalForceContent;